import React from 'react';
import './Projects.css';
export default function Projects() {
    return (
    <>
    
    <div className="project-section">
    <div className="container">
    <iframe src='https://widgets.sociablekit.com/instagram-reels/iframe/25449866' title="insta feed" frameborder='0' width='100%' height='1000'></iframe>
    </div>
    </div>
    </>
    )
}