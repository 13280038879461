import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Home.css';
import sliderimg1 from '../images/slider1.webp';
import sliderimg2 from '../images/slider2.webp';
import sliderimg3 from '../images/slider3.webp';
import designimg from '../images/webdesign.jpg';
import projectimg1 from '../images/1.JPG';
import projectimg2 from '../images/2.JPG';

export default function Home() {
  const owlresponsive = {
    margin: 30,
      responsiveClass: true,
      nav: false,
      dots: true,
      autoplay: false,
     // navText: ["Prev", "Next"],
      smartSpeed: 1000,
      responsive: {
          0: {
              items: 1,
          },
          400: {  
              items: 1,
          },
          600: {
              items: 1,
          },
          700: {
              items: 1,
          },
          1000: {
              items: 1,
          }
      },
  };

  return (
    <>
   <OwlCarousel {...owlresponsive} className="slider owl-theme" loop nav margin={0} >
           <div><img  className="img" src= {sliderimg1} alt="" /></div>  
           <div><img  className="img" src= {sliderimg2} alt="" /></div>  
           <div><img  className="img" src= {sliderimg3} alt="" /></div> 
    </OwlCarousel> 
    <div className="homeProjects">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                <div className="projectListWrap">
                    <div className="projectImg">
                        <img src= {projectimg1} alt='' />
                        
                    </div>
                    <h3><a href="/">Residential</a></h3>
                </div>
                </div>

                <div className="col-md-4">
                <div className="projectListWrap">
                    <div className="projectImg">
                        <img src= {projectimg2} alt='' />
                        
                    </div>
                    <h3><a href="/">Commercial</a></h3>
                </div>
                </div>

                <div className="col-md-4">
                <div className="projectListWrap">
                    <div className="projectImg">
                        <img src= {designimg} alt='' />
                       
                    </div>
                    <h3><a href="/">Institutional</a></h3>
                </div>
                </div>
            </div>
        </div>
    </div>

    <div className="homeAbout">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                <img src= {designimg} alt='' />
                </div>
                <div className="col-md-6 pt-5 pb-5">
                    <h3>Dee,Edge Architects</h3>
                   <p>DEE’EDGE Architects is a Multidisciplinary Architectural firm established by Ar Deepak Sharma in 2023 in Delhi NCR having its Corporate office at NOIDA. The firm specializes in design and Building projects. The firm is committed to offer world class turnkey solutions from conceptual design to Execution to the extent of total satisfaction of the customer. We conceptualize and build Interior and Exterior spaces & Our well trained, high skilled team is responsible for the planning and designing of Residential, Recreational, Commercial, and Industrial Buildings & Structures by applying their knowledge of design, Construction procedures, Zoning Regulations, Building Codes and Building materials.</p> 
<p>Principal Architect of the firm Ar Deepak Sharma based at NOIDA having due professional approach to work combining total attention, client oriented, focussed on top tactile and sensory qualities of space. Due to his sole professionalism, the firm is actively engaged in integrating localized skills and resources with state of art material and world class technologies in order to provide one stop solution for all design requirements.</p> 

                </div>
            </div>
        </div>
    </div>

      
   </>

  )
}