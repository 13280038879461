import React from 'react';
import propTypes from 'prop-types';
import logo from '../images/deeedge-logo.png';

export default function Header(props) {
  return (
    <header className="header">
    <div className="container">
      
      <div className="row">
        <div className="col-md-12 d-flex align-items-center justify-content-between">
        <h1 className="logo pt-3 pb-3 mb-0"><img  className="img" src= {logo} alt="" /></h1>
        <div className="hemburgerMenu"><i className="fa fa-bars"></i></div>
          <nav className="mainMenu">
            <ul className="d-flex align-items-center">
              <li><a href="/">Home</a></li>
              <li><a href="/about">{props.aboutText}</a></li>
              <li><a href="/projects">Projects</a></li>
              <li><a href="/">News</a></li>
              <li className="btn"><a href="/contact"><span>Contact Us</span><div>Contact Us<div className='bg'></div></div></a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

  </header>
  )
}

Header.propTypes = {
    aboutText: propTypes.string.isRequired
}

Header.defaultProps = {
    title: "Hello",
    aboutText: "About Us"
}