import React from 'react';
import deepakimg from '../images/deepak.jpg';
import './About.css';
export default function About() {
    return (
    <>
    <div className="banner">

    </div>
    <div className="aboutpage">
        <div className="container">
            <div className="row align-center">
                <div className="col-md-5"><img  className="img" src= {deepakimg} alt="" /></div>
                <div className="col-md-1"></div>
                <div className="col-md-6">
                <h6>(Design Head & Founder)</h6>
                <h4>Deepak Sharma</h4>
                <p>Deepak Sharma is the Design Head & founder of Dee’Edge Architects, an Interior and Architecture firm specializing in Design and Build projects. Deepak pursued a Bachelor of Architecture from POORNIMA UNIVERSITY, Jaipur- Rajasthan in 2021 and was associated with a Gurugram based prestigious firm for three years as a Senior Architect after which he started his own venture with the name of <strong>Dee’Edge Architects</strong>. The Firm believes in creating environments that are contemporary, multi-layered and sensitive to relative conditions,  under the vibrant leadership of Mr. Deepak Sharma and since then worked hard toward providing world class townships, shopping malls, housing complexes, offices and hospitality.
The firm’s principal and staff have diversified knowledge and experience in the fields of Architecture, Interior Design, Site Planning, and Construction Administration. The practice has its own resource of qualified architects and designers and a large, fully computerized database of technical information.</p>

                </div>
            </div>
        </div>
    </div>
    </>
    )
}