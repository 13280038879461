import React from "react";
import './Footer.css';

export default function footer()
{
 return(
    <>
    <div className="footer">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                <h4>Where to Find Us</h4>
                <ul className="contactInfo">
                        <li>
                            <i className="fa fa-map-marker"></i>
                            <div className="info"><h5>Dee'Edge Architects</h5>C-1058, 10th Floor, Gaur City Mall, Gaur Chowk, Sec-4, Greater Noida West,
Distt- Gautam Budh Nagar</div>

                        </li>
                        <li>
                            <i className="fa fa-map-marker"></i>
<div className="info">G-903, 9th floor, Shree Vardhman Mantra, Ansal Esencia Society Rd, C Block, Ramgarh, Sector 67, Gurugram, Haryana 122102</div>
                        </li>
                </ul>
                    
                </div>
                <div className="col-md-4">
                    <h4>Newsletter</h4>
                    <p>Subscribe to our newsletter to receive updates on the latest news!</p>
                    <form className="newsletter">
                        <input type="email" placeholder="Subscribe with us"></input> 
                        <input type="submit" value=""></input>
                    </form>
                    <ul className="socialLinks">
                        <li><a href="/"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="/"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="/"><i className="fa fa-instagram"></i></a></li>
                    </ul>
                </div>
                <div className="col-md-4">
                <h4>Contacts</h4>
                    <ul className="contactInfo">
                        <li>
                        <i className="fa fa-phone"></i>
                        <div className="info"><h5>Contact Us</h5><a href="tel:+91 8107893485">+91 8107893485</a><br/><a href="tel:+91 8949735130">+91 8949735130</a></div>
                        </li>
                        <li>
                            <i className="fa fa-envelope-open-o"></i>
                            <div className="info"><h5>Mail Us</h5><a href="mailto:deeedgearchitects@gmail.com ">deeedgearchitects@gmail.com </a></div>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </div>
        
    </div>
    <div className="copyright">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <p>© Copyright 2024 All rights.</p>
            </div>
        </div>
    </div>
    </div>
    </>
 )   
}