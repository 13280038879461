import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './fonts/stylesheet.css';
import React from "react";
import AnimatedCursor from "react-animated-cursor";
import Header from './components/Header';
import Home from './Pages/Home';
import About from './Pages/About';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import Footer from './components/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
  return (
    
    <>
      <AnimatedCursor
      innerSize={8}
      outerSize={30}
      color='0, 0, 0'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={2}
      outerStyle={{
        border: '2px solid #ccc'
      }}
      innerStyle={{
        backgroundColor: '#000'
      }}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link',
        {
          target: '.custom',
          options: {
            innerSize: 12,
            outerSize: 12,
            color: '255, 255, 255',
            outerAlpha: 0.3,
            innerScale: 0.7,
            outerScale: 5
          }
        }
      ]}
    />
    <Header title="Web Design" />
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/projects" element={<Projects/>} />
          <Route path="/contact" element={<Contact/>} />
      </Routes>
    </BrowserRouter>
    <Footer/>
    </>
  );
}

export default App;
