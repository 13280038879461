import React from 'react';
import './Contact.css';
export default function Contact() {
    return (
    <>
    <div className="contact-p">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <h3>Contact us</h3>
                <ul className="contactsInfo">
                    <li><i className="fa fa-map-marker"></i><div className="contactInfotext">C-1058, 10th Floor, Gaur City Mall, Gaur Chowk, Sec-4, Greater Noida West, Distt- Gautam Budh Nagar</div></li>
                    <li><i className="fa fa-map-marker"></i><div className="contactInfotext">G-903, 9th floor, Shree Vardhman Mantra, Ansal Esencia Society Rd, C Block, Ramgarh, Sector 67, Gurugram, Haryana 122102</div></li>
                    <li><i className="fa fa-phone"></i><div className="contactInfotext"><a href="tel:+91 8107893485">+91 8107893485</a>, <a href="tel:+91 8949735130">+91 8949735130</a></div></li>
                    <li><i className="fa fa-envelope-open-o"></i><div className="contactInfotext"><a href="mailto:deeedgearchitects@gmail.com ">deeedgearchitects@gmail.com </a></div></li>
                </ul>
            </div>
            <div className="col-md-6">
                <form>
                    <h3>Let's Discuss your project</h3>
                    <p>Fill out the form and our team will contact you soon.</p>
                    <div class="row">
                        <div class="col-md-6"><input type="text" placeholder="Name"></input></div>
                        <div class="col-md-6"><input type="email" placeholder="Email"></input></div>
                        <div class="col-md-12"><input type="Phone" placeholder="Phone"></input></div>
                        <div class="col-md-12"><textarea placeholder="Message"></textarea></div>
                        <div class="col-md-12"><input type="submit" value="Submit"></input></div>
                    </div>
                </form>
            </div>
        </div>

        <div className="mapSection">
            <div className="row">
                <div className="col-md-6">
                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d14012.099813431114!2d77.4165250024424!3d28.599028210925134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sC-1058%2C%2010th%20Floor%2C%20Gaur%20City%20Mall%2C%20Gaur%20Chowk%2C%20Sec-4%2C%20Greater%20Noida%20West%2C%20Distt-%20Gautam%20Budh%20Nagar!5e0!3m2!1sen!2sin!4v1714101422965!5m2!1sen!2sin" width="600" height="450"></iframe>
                </div>
                <div className="col-md-6">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3510.1240965311135!2d77.05898997527953!3d28.385319575800604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2320fc76e13f%3A0x6c4f10042f76dca!2sShree%20Vardhman%20Mantra%20Towers!5e0!3m2!1sen!2sin!4v1714101331589!5m2!1sen!2sin" width="600" height="450"></iframe>
                </div>
            </div>
        </div>
    </div>
    </div>
    </>
 )
}